<template>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <SPForm :form-type="formType" :form-category="formCategory" :form-id="formId" :is-pos="true"></SPForm>
            </div><!--/. container-fluid -->
        </section>
        <!-- /.content -->
    </div>
</template>

<script>
import SPForm from "@/components/app/common/SPForm";

export default {
    name: 'SalePOS_view',
    data: function() {
        return {
            pageName: 'POS',
            segments: [],
            formType: this.$globalEnums.formTypes.Sale,
            formCategory: this.$globalEnums.formCategories.Invoice,
            formId: 0,
        }
    },
    components: {
        SPForm,
    },
    created() {
        this.formId = this.$route.params.id;
        this.formCategory = this.$route.params.type;
    }
}
</script>

<style scoped>
.content-wrapper{
    margin-left: 0 !important;
    margin-top: 0 !important;
}
</style>
